const ProjectDesc = ({ description }) => {
	return (
		<div>
			<p className="indent-2 ml-5 text-center sm:text-left py-[6rem] sm:py-[6rem] md:py-[2rem] lg:py-[6rem] xl:py-[10rem] sm:px-[5rem] p-[1rem] text-[2rem] sm:text-[1rem] lg:text-[1.2rem] xl:text-[1.4rem] antialiased hover:subpixel-antialiased project-desc">
				{description}
			</p>
		</div>
	)
}

export default ProjectDesc;