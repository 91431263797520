import Author from "../images/author.png";
import "../styles/Contact.css";

const Contact = () => {
    return (
		<div className="mt-[15rem]">
			<div className="md:grid md:grid-cols-2">
				<div className=" grid place-items-center">
					<div className="">
						<p>DENNIS KONOI</p>
						<p>+358440609664</p>
						<p>dennis.konoi.@hotmail.com</p>
					</div>
				</div>
				<div className="grid place-items-center">
					<div>
						<img src={Author} className=" w-[30rem]"/>
					</div>
				</div>
			</div>
		</div>
    );
}

export default Contact;