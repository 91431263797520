const ProjectHeader = ({ header }) => {
	return (
		<div>
			<h1 className="px-[8.2rem] md:p-[2rem] md:px-[5rem] text-[4rem] md:text-[4rem] lg:text-[6rem] xl:text-[7.5rem] 2xl:text-[9rem] h-1 w-full project-header">
				{header}
			</h1>
		</div>
	);
};

export default ProjectHeader;
