import './App.css';
import { Routes, Route } from "react-router-dom";
import Portfolio from "./components/Portfolio";
import Commissions from "./components/Commissions";
import Contact from "./components/Contact";
import Landing from "./components/Landing";
import ProjectPage from './components/ProjectPage';
import Menubar from './components/Menu';

function App() {
  return (
	<div>
		<Menubar/>
		<Routes>
			<Route path="/" element={<Landing />} />
				{/* <Route path="/portfolio" element={<Portfolio />} /> */}
					{/* <Route path="/commissions" element={<Commissions />} /> */}
						<Route path="/contact" element={<Contact />} />
							<Route path="/project/:id" element={<ProjectPage />} />
		</Routes>
	</div>
  );
}

export default App;
