import '../styles/Landing.css';
import intro1 from '../images/Mysse_Loader.mp4';
import intro2 from '../images/Antler_BW.mp4';
import intro3 from '../images/Antler_BW3.mp4';
import { useRef, useEffect, useState } from 'react';
import {imageArray, random20imageArray, random22imageArray, random35imageArray, firstSingle, secondSingle} from '../images/imageArray';

const Landing = () => {
	//const videoRef = useRef(null);

	/* useEffect(() => {
		let timeout1;
		
		if (videoRef.current) {
		  videoRef.current.play();
		}
	  
		timeout1 = setTimeout(() => {
		  if (videoRef.current) {
			videoRef.current.classList.add('fade-out');
		  }
		}, 2200);
	  
		return () => {
		  clearTimeout(timeout1);
		};
	  }, [videoRef]); */

	function getRandomNumberAsStringHorizontal(randomNumber) {
		randomNumber +=  Math.floor(Math.random() * (8 - 4 + 1)) + 4;
		return randomNumber.toString();
	}

	function getRandomNumberAsStringVertical() {
		const randomNumber = Math.floor(Math.random() * (400 - 250 + 1)) + 250;
		return randomNumber.toString();
	}

	function getRandomNumberAsStringImageSize() {
		const randomNumber = Math.floor(Math.random() * (40 - 25 + 1)) + 25;
		return randomNumber.toString();
	}

	function shuffleArray(array) {
		const shuffledArray = [...array];
		
		for (let i = shuffledArray.length - 1; i > 0; i--) {
		  const j = Math.floor(Math.random() * (i + 1));
		  [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
		}
		
		return shuffledArray;
	  }

	  function isEven(number) {
		return number % 2 === 0;
	  }
	
	const shuffledImageArray = shuffleArray(imageArray);
    const shuffledRandom20ImageArray = shuffleArray(random20imageArray);
	const shuffledRandom22ImageArray = shuffleArray(random22imageArray);
	const shuffledRandom35ImageArray = shuffleArray(random35imageArray);

	return (
		<div>
			<div className="lg:hidden">
			<div className="relative" style={{}}
				>
					{shuffledImageArray.map((img, index) => {
						return (
							<div style={{}}>
								<img key={index + 1} src={img} alt={{}} style={{padding:'15px'}}/>
							</div>
						)
					})}
				</div>
			</div>
			<div className="hidden lg:block">
					{/* <div className="video-container">
						<video
							className="intro-video h-[90rem] w-[120rem]"
							src={intro1}
							ref={videoRef}
							autoPlay
							muted
							playsInline
						/>
					</div> */}
				<div id="image-gallery-container" className="flex" style={{}}>
					<div className="left-div" style={{flex: '1', padding: '5px', /* background: 'red' */}}>
						{shuffledRandom20ImageArray.map((img, index) => {
							
							if(isEven(index)) return false
							return (
								<div style={{}}>
									<img key={index + 2} className=" rounded-sm " src={img} alt={{}} style={{width: `${getRandomNumberAsStringImageSize()}vw`, marginLeft: `${getRandomNumberAsStringHorizontal(3)}vw`, marginRight: `${getRandomNumberAsStringHorizontal(0)}vw`, marginTop: `${getRandomNumberAsStringVertical()}px`}}/>
								</div>

							)
						})}
					</div>
					<div className="right-div" style={{flex: '1', padding: '5px', /* background: 'blue' */}}>
					{shuffledRandom20ImageArray.map((img, index) => {
							if(!isEven(index)) return false
							return (
								<div style={{}}>
									<img key={index + 3} className=" rounded-sm " src={img} alt={{}} style={{width: `${getRandomNumberAsStringImageSize()}vw`, marginLeft: `${getRandomNumberAsStringHorizontal(0)}vw`, marginRight: `${getRandomNumberAsStringHorizontal(4)}vw`, marginTop: `${getRandomNumberAsStringVertical()}px`}}/>
								</div>

							)
						})}
					</div>
				</div>
				<div className="parent-container" style={{display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100vw',
					height: '100vh',
					marginTop: '400px',
					marginBottom: '200px'}}
				>
				<img src={firstSingle} alt={{}} style={{width: '750px'}}/>
				</div>
				<div id="image-gallery-container" className="flex" style={{}}>
					<div className="left-div" style={{flex: '1', padding: '5px', /* background: 'red' */}}>
						{shuffledRandom22ImageArray.map((img, index) => {
							
							if(isEven(index)) return false
							return (
								<div style={{}}>
									<img key={index + 4} className=" rounded-sm " src={img} alt={{}} style={{width: `${getRandomNumberAsStringImageSize()}vw`, marginLeft: `${getRandomNumberAsStringHorizontal(3)}vw`, marginRight: `${getRandomNumberAsStringHorizontal(0)}vw`, marginTop: `${getRandomNumberAsStringVertical()}px`}}/>
								</div>

							)
						})}
					</div>
					<div className="right-div" style={{flex: '1', padding: '5px', /* background: 'blue' */}}>
					{shuffledRandom22ImageArray.map((img, index) => {
							if(!isEven(index)) return false
							return (
								<div style={{}}>
									<img key={index + 5} className=" rounded-sm " src={img} alt={{}} style={{width: `${getRandomNumberAsStringImageSize()}vw`, marginLeft: `${getRandomNumberAsStringHorizontal(0)}vw`, marginRight: `${getRandomNumberAsStringHorizontal(4)}vw`, marginTop: `${getRandomNumberAsStringVertical()}px`}}/>
								</div>

							)
						})}
					</div>
				</div>
				<div className="parent-container" style={{display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100vw',
					height: '100vh',
					marginTop: '400px',
					marginBottom: '200px'}}
				>
				<img src={secondSingle} alt={{}} style={{width: '750px'}}/>
				</div>
				<div id="image-gallery-container" className="flex" style={{}}>
					<div className="left-div" style={{flex: '1', padding: '5px', /* background: 'red' */}}>
						{shuffledRandom35ImageArray.map((img, index) => {
							
							if(isEven(index)) return false
							return (
								<div style={{}}>
									<img key={index + 6} className=" rounded-sm " src={img} alt={{}} style={{width: `${getRandomNumberAsStringImageSize()}vw`, marginLeft: `${getRandomNumberAsStringHorizontal(3)}vw`, marginRight: `${getRandomNumberAsStringHorizontal(0)}vw`, marginTop: `${getRandomNumberAsStringVertical()}px`}}/>
								</div>

							)
						})}
					</div>
					<div className="right-div" style={{flex: '1', padding: '5px', /* background: 'blue' */}}>
					{shuffledRandom35ImageArray.map((img, index) => {
							if(!isEven(index)) return false
							return (
								<div style={{}}>
									<img key={index + 7} className=" rounded-sm " src={img} alt={{}} style={{width: `${getRandomNumberAsStringImageSize()}vw`, marginLeft: `${getRandomNumberAsStringHorizontal(0)}vw`, marginRight: `${getRandomNumberAsStringHorizontal(2)}vw`, marginTop: `${getRandomNumberAsStringVertical()}px`}}/>
								</div>

							)
						})}
					</div>
				</div>
			</div>
		</div>
	);

};

export default Landing;
