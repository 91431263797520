import React from 'react';
import testImage from "../images/test.png"
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

const ImgReplace = () => {
	const [open, setOpen] = React.useState(false);

	return (
		<div>
			<img src={testImage} className="button" onClick={() => setOpen(true)}/>
			<Modal open={open} onClose={() => setOpen(false)}>
        		<img src={testImage}/>
      		</Modal>
		</div>
	)
}

export default ImgReplace;