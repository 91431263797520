import p1 from './1.png';
import p2 from './2.png';
import p3 from './3.png';
import p4 from './4.png';
import p5 from './5.png';
import p6 from './6.png';
import p7 from './7.png';
import p8 from './8.png';
import p9 from './9.png';
import f1 from './additional_frontpage_images/f-1.jpg'
import f2 from './additional_frontpage_images/f-2.jpg'
import f3 from './additional_frontpage_images/f-3.jpg'
import f4 from './additional_frontpage_images/f-4.jpg'
import f5 from './additional_frontpage_images/f-5.jpg'
import f6 from './additional_frontpage_images/f-6.jpg'
import f7 from './additional_frontpage_images/f-7.jpg'
import f8 from './additional_frontpage_images/f-8.jpg'
import f9 from './additional_frontpage_images/f-9.jpg'
import f10 from './additional_frontpage_images/f-10.jpg'
import f11 from './additional_frontpage_images/f-11.jpg'
import f12 from './additional_frontpage_images/f-12.jpg'
import f14 from './additional_frontpage_images/f-14.jpg'
import f15 from './additional_frontpage_images/f-15.jpg'
import f16 from './additional_frontpage_images/f-16.jpg'
import f17 from './additional_frontpage_images/f-17.jpg'
import f18 from './additional_frontpage_images/f-18.jpg'
import f19 from './additional_frontpage_images/f-19.jpg'
import f20 from './additional_frontpage_images/f-20.jpg'
import f21 from './additional_frontpage_images/f-21.jpg'
import f22 from './additional_frontpage_images/f-22.jpg'
import f23 from './additional_frontpage_images/f-23.jpg'
import f24 from './additional_frontpage_images/f-24.jpg'
import f25 from './additional_frontpage_images/f-25.jpg'
import f26 from './additional_frontpage_images/f-26.jpg'
import f27 from './additional_frontpage_images/f-27.jpg'
import f28 from './additional_frontpage_images/f-28.jpg'
import f29 from './additional_frontpage_images/f-29.jpg'
import f30 from './additional_frontpage_images/f-30.jpg'
import f31 from './additional_frontpage_images/f-31.jpg'
import f32 from './additional_frontpage_images/f-32.jpg'
import f33 from './additional_frontpage_images/f-33.jpg'
import f34 from './additional_frontpage_images/f-34.jpg'
import f35 from './additional_frontpage_images/f-35.jpg'
import f36 from './additional_frontpage_images/f-36.jpg'
import f37 from './additional_frontpage_images/f-37.jpg'
import f38 from './additional_frontpage_images/f-38.jpg'
import f39 from './additional_frontpage_images/f-39.jpg'
import f40 from './additional_frontpage_images/f-40.jpg'
import f41 from './additional_frontpage_images/f-41.jpg'
import f42 from './additional_frontpage_images/f-42.jpg'
import f43 from './additional_frontpage_images/f-43.jpg'
import f44 from './additional_frontpage_images/f-44.jpg'
import f45 from './additional_frontpage_images/f-45.jpg'
import f46 from './additional_frontpage_images/f-46.jpg'
import f47 from './additional_frontpage_images/f-47.jpg'
import f48 from './additional_frontpage_images/f-48.jpg'
import f49 from './additional_frontpage_images/f-49.jpg'
import f50 from './additional_frontpage_images/f-50.jpg'
import f51 from './additional_frontpage_images/f-51.jpg'
import f52 from './additional_frontpage_images/f-52.jpg'
import f53 from './additional_frontpage_images/f-53.jpg'
import f54 from './additional_frontpage_images/f-54.jpg'
import f55 from './additional_frontpage_images/f-55.jpg'
import f56 from './additional_frontpage_images/f-56.jpg'
import f57 from './additional_frontpage_images/f-57.jpg'
import f58 from './additional_frontpage_images/f-58.jpg'
import f59 from './additional_frontpage_images/f-59.jpg'
import f60 from './additional_frontpage_images/f-60.jpg'
import f61 from './additional_frontpage_images/f-61.jpg'
import f62 from './additional_frontpage_images/f-62.jpg'
import f63 from './additional_frontpage_images/f-63.jpg'



const imageArray = [p1,p2,p3,p4,p5,p6,p7,p8,p9,f1,f2,f3,f4,f5,f6,f7,f8,f9,f10,f11,f12,f14,f15,f16,f17,f18,f19,f20,f21,f22,f23,f24,f25,f26,f27,f28,f29,f30,f31,f32,f33,f34,f35,f36,f37,f38,f39,f40,f41,f42,f43,f44,f45,f46,f47,f48,f49,f50,f51,f52,f53,f54,f55,f56,f57,f58,f59,f60,f61,f62,f63];
const random35imageArray = [f29,f30,f31,f32,f33,f34,f35,f36,f37,f38,f39,f40,f41,f42,f43,f44,f45,f46,f47,f48,f49,f50,f51,f52,f53,f54,f55,f56,f57,f58,f59,f60,f61,f62,f63];
const random22imageArray = [f14,f15,f16,f17,f18,f19,f20,f21,f22,f23,f24,f25,f26, f28];
const random20imageArray = [p1,p2,p3,p4,p5,p6,p7,p8,p9,f1,f2,f3,f4,f5,f6,f7,f8,f9,f10,f11];

const firstSingle = f12;
const secondSingle = f27;
export {imageArray, random20imageArray, random22imageArray, random35imageArray, firstSingle, secondSingle};