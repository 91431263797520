import '../styles/Menubar.css';
import DK from '../images/dkfont.png';
/* import WORK from '../images/work.png';
import PORTFOLIO from '../images/Portfolio.png'; */
import CONTACT from '../images/Contact.png';

const Menubar = () => {
	return (
		<div className="flex justify-between items-center  mx-auto px-10 h-24">
			<a className="w-full dennis-konoi" href="/">
				<img src={DK} className="w-80"/>
			</a>
			<ul className="flex mt-1 mr-6">
				{/* <a className="p-2 text-xs" href="/commissions">
					<img src={WORK} className="dennis-konoi w-40"/>
				</a> */}
				{/* <a className="p-2 text-xs" href="/portfolio">
					<img src={PORTFOLIO} className="dennis-konoi w-40"/>
				</a> */}
				<a className="p-2 text-xs" href="/contact">
					<img src={CONTACT} className="dennis-konoi w-40"/>
				</a>
			</ul>
		</div>
	);
};

export default Menubar;
