import { useParams } from 'react-router-dom';
import '../styles/ProjectPage.css';
import ProjectHeader from './ProjectHeader';
import ProjectDesc from './ProjectDesc';
import Author from '../images/camel.jpg';
import ImgReplace from './ImgReplace';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';

/* import React from 'react';


const AspectRatioDemo = () => (
  <div className="Container">
    <AspectRatio.Root ratio={16 / 9}>
      <img
        className="Image"
        src="https://images.unsplash.com/photo-1535025183041-0991a977e25b?w=300&dpr=2&q=80"
        alt="Landscape photograph by Tobias Tullius"
      />
    </AspectRatio.Root>
  </div>
); */

const ProjectPage = () => {
	let { id } = useParams();
	const descriptionArray = [
		'mockstring',
		'Solstice Festival is a gathering for people who enjoy music, art and nature, preferably combined. It takes place during midsummer (22.-24.6) in northern Finland next to the Arctic Circle. The festival site, Rukatunturi, is an arctic fjeld which rises half a kilometer above sea level, giving people picturesque views over pine trees and lakes. That time of the year combined with the northern location means the sun doesn’t set at all, creating a unique atmosphere — a celebration of light and life.',
		"The bush began to shake. Brad couldn't see what was causing it to shake, but he didn't care. he had a pretty good idea about what was going on and what was happening. He was so confident that he approached the bush carefree and with a smile on his face. That all changed the instant he realized what was actually behind the bush.",
		"Sheikki’s High on Life collection of origninal and unique photographic artworks are a shout out to positivity. Sheikki’s art is displayed across different spaces having made collaborations with hotels, clothing for artists, a craft beer being released in this autumn, paintings and custom works for clients. High on Life its on a growing hype and the collection has arrived to roam the metaverse in online galleries and digital spaces.",
		"Product photography for Aalto Yliopisto - Graduates of Interior Architecture class ",
		"Crusty party at abandoned Military base",
		"Electronic music festival in Helsinki ",
		"Medieval Rave with wizards",
		"Photo & Video work done for Caracara Collective ",
		"Base images shot for Veli Studio x Maria Korkeila merchandise campaign 2021"

	];
	const headerArray = ['Grasshopper', 'Solstice', 'Bushman', 'Sheikki', "Aalto", "Curiosity Trip", "Visio Festival", "Retriitti", "CARACARA Collective", "Veli-Studio - Maria Korkeila Editorial",];
	const header = headerArray[id];
	const description = descriptionArray[id];

	return (
		<div className="">
			<div className="md:grid md:grid-cols-2 sm:flex">
				<div className="md:flex md:flex-col md:space-y-5">
					<ProjectHeader header={header} />
					<ProjectDesc description={description} />
				</div>
				<div>
					<img src={Author} className="px-10" />
				</div>
			</div>
			<div className="image-container-project p-10">
				<ImgReplace />
				<ImgReplace />
				<ImgReplace />
				<ImgReplace />
				<ImgReplace />
				<ImgReplace />
				<ImgReplace />
				<ImgReplace />
				<ImgReplace />
				<ImgReplace />
				<ImgReplace />
				<ImgReplace />
				<ImgReplace />
				<ImgReplace />
				<ImgReplace />
				<ImgReplace />
				<ImgReplace />
			</div>
		</div>
	);
};

export default ProjectPage;
